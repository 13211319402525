<template>
  <div class="index">
    <!-- 大背景图 -->
    <div class="background">
      <img src="../../assets//首页//sybjtu.jpg" />
    </div>
    <!-- 成为行业的标杆企业模块 -->
    <div
      class="learnMoreBgc animate__animated animate__fadeInUp wow"
      data-wow-duration="1s"
      data-wow-offset="200"
    >
      <!-- 公司图 -->
      <div class="companyPic">
        <img src="../../assets//首页//shouyegst1.png" />
      </div>
      <div class="rightinfo">
        <div class="title">
          <h1>成为行业的标杆企业</h1>
          <span class="underline"></span>
        </div>
        <div class="content">
          我们通过为企业提供精益制造理念和区块链数据采集跟踪技术，协助制造型企业完成智能化改造和数字化转型，即“智改数转”的目标，完成智慧工厂的建设，实现工厂生产溯源信息化，提升企业各项经济指标。
        </div>
        <img
          class="linkLearnMore big"
          src="../../assets//首页//Learnmore.png"
          @click="handleImageClick"
        />
      </div>
    </div>
    <!-- 智能制造、数字基建、模式创新模块 -->
    <div
      class="introduceThree animate__animated animate__fadeInUp wow"
      data-wow-duration="1s"
      data-wow-offset="200"
    >
      <div class="item big">
        <img src="../../assets/首页//icon1.png" />
        <h1>智能制造</h1>
        <p>
          提炼工厂生产过程中的关键需求，将技术、装备、工艺和软件等要素结合起来，打造出智能制造的典型场景。
        </p>
      </div>
      <div class="item big">
        <img src="../../assets/首页//icon2.png" />
        <h1>数字基建</h1>
        <p>
          应用云计算、大数据、区块链等技术实现企业内数据的有效治理和分析利用，推动数据安全可信流通。
        </p>
      </div>
      <div class="item big">
        <img src="../../assets/首页//icon3.png" />
        <h1>模式创新</h1>
        <p>
          利用网络协同制造优化配置制造资源，利用人工智能技术提高智能制造装备与人员的交互，高质量、高效率的提供产品服务。
        </p>
      </div>
    </div>
    <!-- 智改数转 -->
    <div
      class="smartChange animate__animated animate__fadeInUp wow"
      data-wow-duration="1s"
      data-wow-offset="200"
    >
      <div class="top">
        <div class="top_left">
          <img src="../../assets//首页//fuhao.png" alt="" />
          <div class="top_left_title">
            <p>实现企业“智改数转”</p>
            <span class="top_left_underline"></span>
          </div>
          <div class="top_left_desc">
            <p style="margin-bottom: 1.508vw">
              智能制造并非简单的“机器换人”，面对陌生的数字化领域，特别是当前数字化转型正在呈现新的趋势，一些企业特别是中小企业对此处于观望状态。
            </p>
            <p>
              “智改数转”不仅仅是软硬件的组合重置，更是对企业全方位、全角度、全链条、全生命周期的重塑与再造。
            </p>
          </div>
        </div>
        <div class="top_right_img">
          <img src="../../assets//首页//shujutu.png" alt="" />
        </div>
      </div>
      <div class="bottom">
        <div class="row">
          <div class="bottom_item">
            <img src="../../assets//首页//gongyinglian.png" />
            <div>
              <span class="item_title">供应链管理</span>
              <p>优化采购策略，实现供应链智慧管理</p>
            </div>
          </div>
          <div class="bottom_item">
            <img src="../../assets//首页//zhiliangbaobiao.png" alt="" />
            <div>
              <span class="item_title">质量管控</span>
              <p>部署智能检测装备等，实现精细化质量管控。</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="bottom_item">
            <img src="../../assets//首页//chuangxinchanpin.png" alt="" />
            <div>
              <span class="item_title">产品制造</span>
              <p>开展订单驱动的计划排程，提高生产效率。</p>
            </div>
          </div>
          <div class="bottom_item">
            <img src="../../assets//首页//nengyuannenghaoguanli.png" alt="" />
            <div>
              <span class="item_title">设备能耗</span>
              <p>部署智能传感与控制设备等，全面检测生产数据。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 智改数转核心 -->
    <div
      class="smartChangeCore animate__animated animate__fadeInUp wow"
      data-wow-duration="1s"
      data-wow-offset="200"
    >
      <div class="whiteBox">
        <div class="text">
          <h1>智改数转的核心</h1>
          <div>
            在于提高生产的效率、减少人力以及减少废料。这就需要对整个生产过程实现数据采集、过程监控、设备管理、质量管理、生产调度以及数据统计分析，通过实时监测情况来达到目的。
          </div>
        </div>
      </div>
      <!-- 流程图 -->
      <div>
        <img
          src="../../assets//首页//shujutu2.png"
          calss="introduceDate"
          style="width: 2.3958vw"
        />
      </div>
      <div class="textBox">
        <h1>可信数据</h1>
        <div>
          <div class="paragraph">
            <h4>提升质管水平</h4>
            <span>
              通过区块链对采集的生产数据的进行可溯源防篡改的数据仓库搭建。为质控提供数据依据。
            </span>
          </div>
          <div class="paragraph">
            <h4>产品全生命周期记录</h4>
            <span>
              基于区块链技术的高可靠性和分布式对等设定，建立可信的产品全生命周期记录。
            </span>
          </div>
          <div class="paragraph">
            <h4>链接多种智能系统</h4>
            <span>为MES，ERP，WMS，PLM等各类系统提供统一的可信数据接口。 </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 他们选择锤链 -->
    <div
      class="choose animate__animated animate__fadeInUp wow"
      data-wow-duration="1s"
      data-wow-offset="200"
    >
      <div class="top">
        <img src="../../assets/./首页/hezuohuoban.png" alt="" />
      </div>
      <div class="bottom">
        <img :src="item.url" alt="" v-for="item in theyChoose" :key="item.id" />
      </div>
    </div>
    <!-- 底部 -->
    <FooterView></FooterView>
  </div>
</template>

<script>
export default {
  name: 'IndexView',
  data() {
    return {
      // 他们选择锤链8个图
      theyChoose: [
        { url: require('../../assets/首页/合作伙伴/hzhblogo1.png'), id: 1 },
        { url: require('../../assets/首页/合作伙伴/hzhblogo2.png'), id: 2 },
        { url: require('../../assets/首页/合作伙伴/hzhblogo3.png'), id: 3 },
        { url: require('../../assets/首页/合作伙伴/hzhblogo4.png'), id: 4 },
        { url: require('../../assets/首页/合作伙伴/hzhblogo5.png'), id: 5 },
        { url: require('../../assets/首页/合作伙伴/hzhblogo6.png'), id: 6 },
        { url: require('../../assets/首页/合作伙伴/hzhblogo7.png'), id: 7 },
        { url: require('../../assets/首页/合作伙伴/hzhblogo8.png'), id: 8 },
        { url: require('../../assets/首页/合作伙伴/hzhblogo9.png'), id: 9 }
      ]
    }
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    })
    this.$nextTick(() => {
      this.$wow.init()
    })
  },
  methods: {
    handleImageClick() {
      this.$router.push('/about')
    }
  }
}
</script>
<style lang="scss" scoped>
$primary-color: #c21920;
.background img {
  width: 100%;
  height: 100%;
}
/* 公司图 */
.learnMoreBgc {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 41.6667vw;
  background-image: url('../../assets//首页//sybjt2.png');
  background-size: cover; /* 添加这行 */
  background-repeat: no-repeat; /* 添加这行 */
  background-position: center; /* 添加这行 */
}
/* 公司图片 */
.learnMoreBgc .companyPic {
  width: 20.8333vw;
}
.learnMoreBgc .companyPic > img {
  width: 100%;
}
/* 成为行业标杆企业盒子 */
.learnMoreBgc .rightinfo {
  width: 24.5625vw;
  margin: 0 4.0729vw;
  /* background-color: palevioletred; */
  .content {
    margin: 1.5208vw 0 2.5625vw 0;
    line-height: 2.5;
    font-size: 0.8333vw;
  }
  .linkLearnMore {
    width: 10.4167vw;
  }
}
/* 标题 */
.learnMoreBgc .rightinfo .title {
  height: 3.6458vw;
  position: relative;
  h1 {
    font-size: 1.5625vw;
  }
  .underline {
    position: absolute;
    bottom: 0;
    width: 1.5625vw;
    height: 0.3125vw;
    background-color: $primary-color;
  }
}

/* 智能制造、数字基建、模式创新模块  */
.introduceThree {
  padding: 0 12.0417vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 23.5417vw;
  background: url('../../assets/首页//sybjt3.png') center/cover no-repeat;
  color: #fff;
  .item {
    width: 18.75vw;
    display: flex;
    flex-warp: warp;
    flex-direction: column; /* 修改为垂直主轴方向布局 */
    justify-content: space-between;
    align-items: center;
    img {
      width: 6.8229vw;
    }
    h1,
    p {
      width: 100%;
      text-align: center;
    }
    h1 {
      margin-top: 0.3125vw;
      font-size: 1.5625vw;
    }
    p {
      margin-top: 1.0417vw;
      line-height: 1.8;
      font-size: 0.9375vw;
    }
  }
}

/* 智改数转 */
.smartChange {
  height: 55.2083vw;
  padding: 4.6875vw 0 0 15.625vw;
  .top {
    display: flex;
  }

  .top_left {
    width: 43.75vw;
    img {
      width: 3.125vw;
    }
    .top_left_title {
      position: relative;
      color: $primary-color;
      font-size: 1.6667vw;
      font-weight: 700;
      padding: 1.875vw 0;

      .top_left_underline {
        position: absolute;
        bottom: 0;
        display: block;
        width: 3.9583vw;
        height: 0.1563vw;
        background-color: black;
      }
    }
    /* 文字描述 */
    .top_left_desc {
      width: 43.2292vw;
      margin-top: 2.1875vw;
      p {
        font-size: 1.15vw;
        line-height: 2.2;
        /* font-weight: 700; */
        font-family: 'Microsoft YaHei';
      }
    }
  }
  .top_right_img {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 25.625vw;
    }
  }
  /* 四个 */
  .bottom {
    /* background-color: palegreen; */
    height: 28.6458vw;
    padding: 0 5.2083vw;
    display: flex;
    justify-content: center;
    .row {
      flex: 1;
      display: flex;
      flex-direction: column; /* 修改为垂直主轴方向布局 */
      justify-content: space-around;
    }
    .bottom_item {
      display: flex;
      justify-content: start;
      align-items: center;
      img {
        height: 4.3229vw;
      }
      p {
        width: 13.5417vw;
        margin-top: 1.3021vw;
        font-size: 0.8333vw;
        line-height: 1.8;
      }
      .item_title {
        font-size: 1.3542vw;
      }
      > div {
        margin-left: 2.0833vw;
      }
    }
  }
}

/* 智改数转核心 */
.smartChangeCore {
  /* width: 100%; */
  height: 46.625vw;
  padding-left: 7.2917vw;
  background-image: url('../../assets//首页//sybjt4.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  .whiteBox {
    width: 42.7083vw;
    height: 32.9167vw;
    margin-right: 3.8021vw;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      width: 29.7917vw;
      height: 12.2917vw;
      h1 {
        font-size: 2.1875vw;
      }
      div {
        margin-top: 2.6042vw;
        font-size: 1.0417vw;
        line-height: 2;
      }
    }
  }

  .textBox {
    width: 26.6042vw;
    /* background-color: pink; */
    margin-left: 1.5625vw;
    color: #fff;
    h1 {
      font-size: 2.1875vw;
      margin: 1.8625vw 0;
    }
    .paragraph {
      font-weight: 100;

      margin-top: 1.9625vw;
      h4 {
        font-size: 1.3542vw;
        margin-bottom: 0.7813vw;
      }
      span {
        font-size: 1.0417vw;
        line-height: 1.6;
      }
    }
  }
}

/* 他们选择锤链 */
.choose {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 39.375vw;
  background-image: url('../../assets//首页//sybjt5.png');
  background-size: cover; /* 添加这行 */
  background-repeat: no-repeat; /* 添加这行 */
  background-position: center; /* 添加这行 */
  .top {
    margin-top: 3.6458vw;
    img {
      height: 1.875vw;
      margin-bottom: 2.6042vw;
    }
  }
  .bottom {
    width: 74.1771vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* flex-direction: column; */
    margin-bottom: 7.8125vw;
    img {
      width: 21.0938vw;
    }
  }
}
</style>

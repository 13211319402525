import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/index/index.vue'

Vue.use(VueRouter)

const routes = [
  // 1.首页
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  // 2.产品中心
  {
    path: '/productCenter',
    name: 'productCenter',
    redirect: '/productCenter/product', // 添加重定向配置
    component: () => import('../views/productCenter/index.vue'),
    children: [
      {
        path: 'product', // 智能生产管理系统
        component: () => import('../views/procuct/index.vue')
      },
      {
        path: 'device', // 智慧设备管理平台
        component: () => import('../views/device/index.vue')
      },
      {
        path: 'ai', // ai获客邮件精灵
        component: () => import('../views/ai/index.vue')
      }
    ]
  },
  // 3.解决方案
  {
    path: '/solution',
    name: 'solution',
    component: () => import('../views/solution/index.vue')
  },
  // 4.关于我们
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/aboutUs/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
